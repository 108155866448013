import React from 'react'
import Head from 'shared/components/Head/Head'

const NotFoundPage: React.FC = () => (
  <>
    <Head title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>La pagina que buscas no existe.</p>
  </>
)

export default NotFoundPage
